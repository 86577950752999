import { useState } from "react";
import {
  Wrapper,
  InputStyle,
  TextAreaStyle,
  SelectWrapper,
  Desc,
  Label,
  DiscountWrapper,
  PwdStyle,
  PwdInput,
} from "./InputStyle";
import Typography from "./../Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FeatherIcon from "feather-icons-react";
import { Primary, Success } from "../../../helpers/Colors";
import Button from "./../Button";

export const Input = ({ size, state, desc, ...props }) => (
  <Wrapper state={state}>
    <InputStyle state={state} size={size} {...props} />
    <Typography variant="p2" style={{ margin: 0 }}>
      {desc}
    </Typography>
  </Wrapper>
);

export const PasswordInput = ({ size, state, desc, ...props }) => {
  const [type, setType] = useState(true);
  return (
    <Wrapper state={state}>
      <PwdStyle state={state}>
        <PwdInput type={type ? "password" : "text"} size={size} {...props} />
        <FeatherIcon
          icon={type ? "eye-off" : "eye"}
          onClick={() => setType(!type)}
        />
      </PwdStyle>
      <Typography variant="p2" style={{ margin: 0 }}>
        {desc}
      </Typography>
    </Wrapper>
  );
};

export const TextArea = ({ state, desc, size, children, ...props }) => (
  <Wrapper state={state}>
    <TextAreaStyle state={state} size={size} {...props}>
      {children}
    </TextAreaStyle>
    <Typography variant="p2" style={{ margin: 0 }}>
      {desc}
    </Typography>
  </Wrapper>
);

export const CustomSelect = ({ state, desc, label, list, ...props }) => {
  const [open, setOpen] = useState(false);
  return (
    <SelectWrapper state={state}>
      <Label>{label} </Label>
      <Select
        {...props}
        variant="standard"
        MenuProps={{
          sx: {
            "& .MuiMenu-paper": {
              boxShadow: "0px 37px 90px 6px rgba(87, 87, 87, 0.12)",
            },
            "& .MuiList-root": {
              boxShadow: "none",
              textTransform: "capitalize",
              background: Primary[800],
              color: Primary[100],
            },
            "& .MuiMenuItem-root": {
              fontFamily: "'Mulish', sans-serif",
              fontSize: "14px",
            },
            "& .Mui-selected": {
              // backgroundColor: "primary.main",
              // color: "text.white",
            },
          },
        }}
        IconComponent={(expanded) =>
          open ? (
            <FeatherIcon icon="chevron-up" />
          ) : (
            <FeatherIcon icon="chevron-down" />
          )
        }
        open={open}
        onClick={() => setOpen(!open)}
      >
        {list.map((val, i) => (
          <MenuItem value={val.id} key={i}>
            {val.name}
          </MenuItem>
        ))}
      </Select>
      <Desc state={state}>{desc}</Desc>
    </SelectWrapper>
  );
};

export const DiscountInput = ({
  label,
  state,
  saved,
  value,
  desc,
  onSubmit,
  isLoading,
  ...props
}) => {
  return (
    <Wrapper>
      <Label>{label} </Label>
      <DiscountWrapper>
        <InputStyle
          {...props}
          placeholder="Enter Discount Code"
          className="input"
          disabled={state === "firstUser" ? true : false}
          value={state === "firstUser" ? "WELCOME-GIFT" : value}
        />

        <Button
          label={state === "success" ? "Remove" : "Apply"}
          size="sm"
          showIcon="left"
          isLoading={isLoading}
          icon={
            <FeatherIcon icon={state === "success" ? "x" : "check"} size={16} />
          }
          style={{ display: "flex", alignItems: "center" }}
          onClick={onSubmit}
        />
      </DiscountWrapper>
      <Desc state={state}>{desc}</Desc>
      {(state === "success" || state === "firstUser") && (
        <div style={{ marginTop: 8 }}>
          <span
            style={{
              background: Success[500],
              padding: "4px 8px",
              borderRadius: 4,
              fontSize: 12,
            }}
          >
            {saved}% Saved
          </span>
        </div>
      )}
    </Wrapper>
  );
};
