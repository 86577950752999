import styled from "styled-components";
import { Primary, Error, Info } from "./../../../helpers/Colors";

export const Wrapper = styled.div`
  margin-bottom: 20px;
  p {
    color: ${(props) => (props.state === "error" ? Error[500] : Primary[100])};
  }
`;

export const PwdStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background: ${Primary[900]};
  padding: 0px 16px;
  border-radius: 5px;
  border: ${(props) =>
    props.state === "error" ? `1px solid ${Error[500]}` : "0px"};
`;

export const PwdInput = styled.input`
  width: 100%;
  height: ${(props) => (props.size === "md" ? "55px" : "40px")};
  border: 0px;
  margin-bottom: 0px;
  box-sizing: border-box;
  background: none;
  color: ${Primary[100]};
  font-size: 14px;
  ::placeholder {
    color: ${Primary[300]};
  }
  &:focus {
    outline: 0px;
  }
`;

export const InputStyle = styled.input`
  width: 100%;
  height: ${(props) => (props.size === "md" ? "55px" : "40px")};
  border: ${(props) =>
    props.state === "error" ? `1px solid ${Error[500]}` : "0px"};
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 0px;
  box-sizing: border-box;
  background: ${Primary[900]};
  color: ${Primary[100]};
  font-size: 14px;
  ::placeholder {
    color: ${Primary[300]};
  }
  &:focus {
    outline: 1px solid ${Primary[300]};
  }
`;

export const SelectWrapper = styled.div`
  margin: 20px 0px;
  .MuiInputBase-root {
    width: 100%;
    height: 50px;
    border: ${(props) =>
      props.state === "error" ? `1px solid ${Error[500]}` : "0px"};
    border-radius: 5px;
    padding: 20px;
    box-sizing: border-box;
    background: ${Primary[900]};
    color: ${Primary[100]};
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    margin-top: 12px;
    ::placeholder {
      color: ${Primary[300]};
    }
    &:focus {
      outline: 1px solid ${Info[300]};
    }
  }

  .MuiInputBase-root::before,
  .MuiInputBase-root::after {
    border-bottom: 0px;
    content: none;
  }

  .MuiInputBase-root:hover > .MuiInputBase-root::before {
    border-bottom: 10px;
  }
`;

export const Desc = styled.span`
  color: ${(props) =>
    props.state === "error" ? `${Error[500]}` : `${Primary[500]}`};
`;

export const Label = styled.label`
  font-size: 14px;
  color: ${Primary[200]};
  text-transform: capitalize;
  font-weight: 600;
`;

export const TextAreaStyle = styled.textarea`
  width: 100%;
  height: 200px;
  border: ${(props) =>
    props.state === "error" ? `1px solid ${Error[500]}` : "0px"};
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  background: ${Primary[900]};
  color: ${Primary[100]};
  font-size: 14px;
  resize: none;
  ::placeholder {
    color: ${Primary[300]};
  }
  &:focus {
    outline: 1px solid ${Primary[300]};
  }
`;

export const DiscountWrapper = styled.div`
  width: 100%;
  height: 50px;
  background: ${Primary[800]};
  display: flex;
  align-items: center;
  padding: 0px 8px;
  box-sizing: border-box;
  margin-top: 12px;
  /* margin-bottom: 12px; */
  border-radius: 8px;

  > svg {
    cursor: pointer;
  }
  .input {
    background: none;
    margin: 0px;
    padding: 0px;
    border-radius: 0px;
    height: 50px;
    &:focus {
      outline: none;
    }
  }
`;
