import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { Link } from "react-router-dom";
import { Input, PasswordInput } from "../../atoms/Input";
import Button from "../../atoms/Button";
import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Grey } from "../../../helpers/Colors";
import { ActionWrapper } from "./LoginFormStyle";
// import LoginWith from "../LoginWith";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import Toast from "../../molecules/toast/Toast";

const Index = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useContext(AuthContext);
  const [notify, showNotify] = useState(false);
  const [notifyMsg, setNotifyMsg] = useState("");
  const [notifyType, setNotifyType] = useState("");

  const handleNotify = (msg, type) => {
    showNotify(true);
    setNotifyMsg(msg);
    setNotifyType(type);
    setInterval(() => {
      showNotify(false);
    }, 5000);
  };

  const formik = useFormik({
    initialValues: {
      ep: "",
      password: "",
    },
    validationSchema: Yup.object({
      ep: Yup.string().required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        await login(values);
        handleNotify("Welcome back", "success");
      } catch (err) {
        handleNotify(err.response.data.message, "error");
      }
      setIsLoading(false);
    },
  });

  return (
    <div>
      <Toast show={notify} type={notifyType} msg={notifyMsg} />
      <form onSubmit={formik.handleSubmit}>
        <Input
          name="ep"
          placeholder="Email or phone number"
          value={formik.values.ep}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          size="md"
          state={formik.touched.ep && formik.errors.ep && "error"}
          desc={formik.touched.ep && formik.errors.ep && formik.errors.ep}
        />

        <PasswordInput
          name="password"
          // type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Password"
          size="md"
          state={formik.touched.password && formik.errors.password && "error"}
          desc={
            formik.touched.password &&
            formik.errors.password &&
            formik.errors.password
          }
        />
        <Button label="Sign In" block={true} isLoading={isLoading} />
        <ActionWrapper>
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: Grey[200],
                  "&.Mui-checked": {
                    color: Grey[200],
                  },
                }}
              />
            }
            label="Remember me"
            sx={{ color: Grey[200] }}
          />

          <Link to="/forgot-password">Forgot Password</Link>
        </ActionWrapper>
      </form>

      <p>
        New to Owosh?{" "}
        <Link to="/register" style={{ textDecoration: "underline" }}>
          Sign up now
        </Link>
      </p>

      {/* <LoginWith /> */}
    </div>
  );
};

export default Index;
