import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Input, PasswordInput } from "../../atoms/Input";
import Button from "../../atoms/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Grey } from "../../../helpers/Colors";
// import LoginWith from "../LoginWith";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import Toast from "../../molecules/toast/Toast";

const RegisterForm = () => {
  const navigate = useNavigate();
  const { newUser } = useContext(AuthContext);
  const [terms, setTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [notify, showNotify] = useState(false);
  const [notifyMsg, setNotifyMsg] = useState("");
  const [notifyType, setNotifyType] = useState("");

  const handleNotify = (msg, type) => {
    showNotify(true);
    setNotifyMsg(msg);
    setNotifyType(type);
    setInterval(() => {
      showNotify(false);
    }, 5000);
  };

  const formik = useFormik({
    initialValues: {
      fullname: "",
      email: "",
      phone: "",
      password: "",
      cPassword: "",
    },
    validationSchema: Yup.object({
      fullname: Yup.string().required("Required"),
      email: Yup.string().email("invalid email address").required("Required"),
      phone: Yup.string().required("Required"),
      password: Yup.string().min(8).required("Required"),
      cPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
    }),
    onSubmit: async (values) => {
      if (terms) {
        setIsLoading(true);
        try {
          values.cPassword = undefined;
          await newUser(values);
          handleNotify("Welcome back", "success");
          navigate("/account");
        } catch (err) {
          handleNotify(err.response.data.message, "error");
        }

        setIsLoading(false);
      } else {
        handleNotify("Agree to Terms & Privacy Policy", "error");
      }
    },
  });

  return (
    <div>
      <Toast show={notify} type={notifyType} msg={notifyMsg} />

      <form onSubmit={formik.handleSubmit}>
        <Input
          placeholder="Full Name"
          size="md"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="fullname"
          value={formik.values.fullname}
          state={formik.touched.fullname && formik.errors.fullname && "error"}
          desc={
            formik.touched.fullname &&
            formik.errors.fullname &&
            formik.errors.fullname
          }
        />
        <Input
          placeholder="Phone Number"
          size="md"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="phone"
          value={formik.values.phone}
          state={formik.touched.phone && formik.errors.phone && "error"}
          desc={
            formik.touched.phone && formik.errors.phone && formik.errors.phone
          }
        />
        <Input
          placeholder="Email"
          size="md"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="email"
          value={formik.values.email}
          state={formik.touched.email && formik.errors.email && "error"}
          desc={
            formik.touched.email && formik.errors.email && formik.errors.email
          }
        />
        <PasswordInput
          placeholder="Password"
          size="md"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="password"
          value={formik.values.password}
          state={formik.touched.password && formik.errors.password && "error"}
          desc={
            formik.touched.password &&
            formik.errors.password &&
            formik.errors.password
          }
        />
        <PasswordInput
          placeholder="Confirm Password"
          size="md"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="cPassword"
          value={formik.values.cPassword}
          state={formik.touched.cPassword && formik.errors.cPassword && "error"}
          desc={
            formik.touched.cPassword &&
            formik.errors.cPassword &&
            formik.errors.cPassword
          }
        />

        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={terms}
              onClick={() => setTerms(!terms)}
              sx={{
                color: Grey[200],
                "&.Mui-checked": {
                  color: Grey[200],
                },
              }}
            />
          }
          label={
            <span style={{ fontSize: 14 }}>
              By signing up you agree to our{" "}
              <strong>
                <Link to="/pages/terms">Terms</Link> &{" "}
                <Link to="/pages/privacy-policy">Privacy Policy</Link>
              </strong>
            </span>
          }
          sx={{ color: Grey[200], margin: "15px 0px" }}
        />

        <Button label="Create Account" block={true} isLoading={isLoading} />
      </form>

      {/* <LoginWith /> */}
    </div>
  );
};

export default RegisterForm;
